.page-title {
  display: flex;
  margin-bottom: 26px;
  align-items: center;
  color: #00269A;
}
.create-new-tag {
  margin-left: auto;
  a {
    background: #4271FF;
    box-shadow: 0px 4px 16px -7px rgba(180, 182, 254, 0.2);
    border-radius: 9px;
    font-family: "Sansation-Bold";
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    padding: 11px 22px;
    text-decoration: none;
    i {
      margin-right: 10px;
    }
    &:hover, &:focus {
      color: #FFFFFF;
    }
  }
}
.tag-card-container {
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  column-gap: 40px;
  row-gap: 40px;
}
.sub-title {
  display: flex;
  font-family: "Sansation-Bold";
  font-size: 18px;
  line-height: 24px;
  color: #454F5B;
  .sort-items {
    margin-left: auto;
    border: 2px solid #DFE3E8;
    border-radius: 8px;
    padding: 11px 18px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    width: 135px;
    position: relative;
    right: 0;
    top: -10px;
    a {
      display: flex;
      text-decoration: none;
      color: #637381;
      &:hover, &:focus {
        outline: none;
        text-decoration: none;
      }
    }
    i {
      margin-left: auto;
      transform: rotate(90deg);
    }
  }
}
.tag-card {
  background: #F4F6F8;
  border-radius: 8px;
  width: 168px;
  height: 97px;
  display: inline-block;
  min-width: 0;
  position: relative;
  .delete-tag {
    position: absolute;
    top: 18px;
    right: 20px;
    z-index: 1;
    color: #454F5B;
  }
  a {
    text-decoration: none;
  }
  .card-header{
    height: 110px;
    background-position: top left;
    background-repeat: none;
    background-size: cover;
  }
  .card-body {
    padding: 12px 16px 20px;
  }
  .tag-title {
    font-family: 'Sansation';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    height: 24px;
    color: #454F5B;
    margin-bottom: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .tag-sub {
    font-family: 'Sansation';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #454F5B;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .used-in { margin-bottom: 5px; }
}

#newTag {
  .modal-content {
    background: #F9FAFB;
    box-shadow: 0px 20px 40px -8px rgba(145, 158, 171, 0.24);
    border: 0;
    border-radius: 15px;
    position: relative;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }
  .modal-header {
    padding: 14px 26px;
    position: relative;
    h2 {
      font-family: 'Sansation-Bold';
      font-size: 24px;
      line-height: 36px;
      color: #00269A;
      margin: 0;
    }
    .close {
      position: absolute;
      top: 22px;
      right: 26px;
      opacity: 1;
      border: 0;
    }
  }
  .modal-body {
    padding: 20px 25px 25px;
    border-radius: 15px;
    .subtitle {
      color: #454F5B;
      margin-bottom: 10px;
      margin-top: 10px;
    }
  }
  .file-upload-section {
    .file-upload-group {
      border: 1px dashed rgba(0, 0, 0, 0.25);
      box-sizing: border-box;
      border-radius: 8px;
      padding: 32px;
      text-align: center;
      margin-bottom: 20px;
      .upload-group {
        position: relative;
        font-weight: 700;
        font-family: "Sansation-Bold";
        font-size: 18px;
        line-height: 26px;
        text-align: center;
        color: #4271FF;
        display: inline-block;
        border-radius: 10px;
        padding: 11px 22px;
        cursor: pointer;
        input[type=file] {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          opacity: 0;
          cursor: pointer;
          font-size: 0;
        }
      }
      span {
        font-family: 'Sansation';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: #919EAB;
      }
    }
    .user-document-group.disabled {
      pointer-events: none;
      opacity: 0.25;
    }
    .user-document {
      font-weight: 700;
      font-family: "Sansation-Bold";
      font-size: 15px;
      line-height: 22px;
      text-align: center;
      color: #454F5B;
      padding: 10px 14px;
      border: 1px solid #C4CDD5;
      border-radius: 8px;
      margin-bottom: 20px;
      display: inline-flex;
      align-items: center;
      img { margin-right: 8px; }
      i { margin-left: 15px; cursor: pointer; }
    }
    .explain-group {
      &.uploaded {
        display: none;
      }
      &.disabled {
        pointer-events: none;
        opacity: 0.25;
        display: inline-block;
      }
    }
  }
  form {
    textarea {
      width: 100%;
      border: 2px solid #DFE3E8;
      border-radius: 10px;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #637381;
    }

    .text-input {
      padding: 11px 18px;
      border: 2px solid #DFE3E8;
      border-radius: 10px;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #637381;
      width: 100%;
    }
    .save-draft {
      font-size: 18px;
      line-height: 26px;
      color: #FFFFFF;
      font-family: "Sansation-Bold";
      padding: 11px 22px;
      background: #4271FF;
      border-radius: 10px;
      display: inline-block;
      margin-top: 30px;
      border: 0;
      align-items: center;
    }
  }
}
