.page-title {
  display: flex;
  margin-bottom: 26px;
  align-items: center;
  color: #00269A;
}
.create-new-course {
  margin-left: auto;
  a {
    background: #4271FF;
    box-shadow: 0px 4px 16px -7px rgba(180, 182, 254, 0.2);
    border-radius: 9px;
    font-family: "Sansation-Bold";
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    padding: 11px 22px;
    text-decoration: none;
    i {
      margin-right: 10px;
    }
    &:hover, &:focus {
      color: #FFFFFF;
    }
  }
}
.course-tabs {
  margin-bottom: 25px;
  .nav-tabs {
    border-bottom: none;
    li {
      padding-right: 40px;
      a, a:hover, a:focus {
        font-family: "Sansation-Bold";
        font-size: 18px;
        line-height: 24px;
        border: 0;
        padding: 0 0 5px 0;
        color: #637381;
        background: transparent;
      }
    }
    li {
      a.active, a:hover, a:focus {
        color: #4271FF;
        background: transparent;
        border: 0;
        border-bottom: 1.5px solid #4271FF;
      }
    }
  }
}
.course-list {
  display: none;
  &.active {
    display: block;
  }
}
.course-card-container {
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  column-gap: 40px;
  row-gap: 40px;
}
.course-card {
  background: #FFFFFF;
  box-shadow: 0px 20px 40px -8px rgba(145, 158, 171, 0.1);
  border-radius: 10px;
  width: 220px;
  height: 255px;
  display: inline-block;
  min-width: 0;
  position: relative;
  a:hover, a:focus { text-decoration: none; }
  .card-header {
    height: 110px;
    position: relative;
    width: 220px;
    display: flex;
    align-items: center;
    img {
      width: 100%;
      height: 100%;
      border-radius: 10px 10px 0 0;
    }
  }
  .delete-course {
    position: absolute;
    top: 9px;
    right: 9px;
    padding: 7px 9px;
    border-radius: 50%;
    background: #DFE3E8;
    font-size: 16px;
    color: #637381;
    cursor: pointer;
    z-index: 1;
  }
  .card-body {
    padding: 12px 16px 20px;
  }
  .course-title {
    font-family: "Sansation-Bold";
    font-size: 16px;
    line-height: 24px;
    height: 48px;
    color: #092651;
    margin-bottom: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .course-desc {
    height: 36px;
    font-size: 12px;
    line-height: 18px;
    color: #454F5B;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}
