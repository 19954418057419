.header-content, .footer-content {
  max-width: 1370px;
  margin: 0 auto;
}
.header-section {
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: #092651;
  border-bottom: 1px solid rgba(128, 163, 255, 0.2);
  background: #EFF3F7;
  box-shadow: 0px 10px 40px -8px rgba(145, 158, 171, 0.1);
  padding: 0 30px;
  .header-content {
    display: flex;
    align-items: center;
    height: 70px;
  }
  .logo {
    height: 24px;
    img { height: 100%; }
  }
  .nav {
    display: inline-flex;
    align-items: center;
    margin-left: auto;
  }
  .notification {
    margin-right: 30px;
    display: inline-flex;
    align-items: center;
  }
  .avatar {
    display: inline-flex;
    align-items: center;
  }
  .avatar i { font-size: 32px; }
  .avatar span {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #161C24;
    margin-left: 10px;
  }
  .dropdown-toggle {
    background: transparent;
    border: none;
    i {
      font-size: 16px;
      color: #777E91;
      display: inline-block;
      vertical-align: middle;
    }
  }
  .dropdown-menu .dropdown-item {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #161C24;
    background: transparent;
  }
}

footer {
  padding: 20px 30px;
  border-top: 1px solid rgba(128, 163, 255, 0.2);
  margin-top: auto;
  .company-info {
    display: inline-flex;
    align-items: center;
    img {
      display: inline-block;
      vertical-align: middle;
    }
    span {
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      margin-left: 16px;
      vertical-align: middle;
    }
  }
  .terms {
    text-align: center;
    margin: 0 auto;
    display: block;
    float: right;
    a, span {
      font-weight: 400;
      color: #919EAB;
      margin-left: 8px;
      font-size: 14px;
      line-height: 22px;
      text-align: center;
    }
  }
}
.main-content-wrapper {
  max-width: 1430px;
  width: 100%;
  margin: 0 auto;
  padding: 0 30px;
}
.employer-left-nav {
  background: #F4F7F9;
  box-shadow: 0px 20px 40px -8px rgba(145, 158, 171, 0.24);
  border-radius: 20px;
  margin: 40px 56px 40px 0;
  display: inline-block;
  -webkit-animation-duration: 0.6s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  transition: all 0.3s ease;
	height: 728px;
  width: 72px;
  vertical-align: top;

  .menu-nav {
    padding: 17px;
    margin: 0;
    min-height: 480px;
    li {
      padding: 12px 0;
      margin-bottom: 25px;
      list-style: none;
      a {
        display: block;
        width: 100%;
        text-decoration: none;
        color: #919EAB;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        &:hover, &:focus {
          outline: none;
          text-decoration: none;
        }
        span {
          vertical-align: middle;
        }
        img {
          margin-right: 12px;
          vertical-align: middle;
          display: inline-block;
        }
      }
      .active-icon { display: none; }
      &.active, &:hover {
        img { display: none; }
        .active-icon { display: inline-block; }
      }
    }
  }
}
.employer-left-nav.expanded {
  width: 245px;
  .toggle-left-menu .collapse { display: block; }
  .toggle-left-menu .expand { display: none; }
  .menu-nav { min-height: 530px; }
  .menu-nav li {
    padding: 12px;
    &.active, &:hover {
      background: #E8EEFF;
      border-radius: 10px;
      a { color: #4271FF; }
    }
  }
}
