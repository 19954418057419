.course-main-container {
  .page-title {
    display: flex;
    h2 {
      margin: 0;
      width: 60%;
      font-size: 24px;
      line-height: 36px;
      color: #00269A;
      font-family: "Sansation-Bold";
    }
  }
  .back-btn {
    color: #00269A;
    margin-right: 19px;
    font-size: 17px;
  }
  .controls-btn { margin-left: auto; display: inline-flex; }
  .publish-module-btn, .create-new-unit, .unpublish-module-btn {
    display: inline-flex;
    background: #4271FF;
    box-shadow: 0px 4px 16px -7px rgba(180, 182, 254, 0.2);
    border-radius: 9px;
    padding: 11px 22px;
    font-family: "Sansation-Bold";
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    text-decoration: none;
    align-items: center;
    img, i {
      margin-right: 11px;
    }
    img { width: 10px; }
  }
  .create-new-unit {
    background: #FFFFFF;
    color: #4271FF;
    margin-left: 20px;
  }
  .course-basic-info {
    margin-top: 20px;
    .title {
      font-family: "Sansation-Bold";
      font-size: 18px;
      line-height: 24px;
      color: #161C24;
      margin-bottom: 15px;
    }
    textarea {
      width: 100%;
      border: 2px solid #DFE3E8;
      border-radius: 10px;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #637381;
      padding: 13px 18px;
      margin-bottom: 20px;
    }

    input {
      padding: 11px 18px;
      border: 2px solid #DFE3E8;
      border-radius: 10px;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #637381;
      width: 100%;
    }
    select {
      padding: 11px 18px;
      border: 2px solid #DFE3E8;
      border-radius: 10px;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #637381;
      width: 100%;
    }
    .save-draft {
      font-size: 18px;
      line-height: 26px;
      color: #FFFFFF;
      font-family: "Sansation-Bold";
      padding: 11px 22px;
      background: #4271FF;
      border-radius: 10px;
      display: inline-block;
      margin-top: 30px;
      border: 0;
      align-items: center;
      width: auto;
      height: 48px;
    }
    input[type=radio] {
      width: 24px;
      height: 24px;
      border: 2px solid #C4CDD5;
      border-radius: 24px;
      vertical-align: middle;
      margin-top: 0;
    }
    label {
      font-size: 16px;
      line-height: 24px;
      color: #454F5B;
      margin-left: 15px;
      margin-bottom: 0;
    }
    .thumbnail-partner {
      display: flex;
      justify-content: space-between;
    }
    .thumbnail-group {
      width: 50%;
      display: inline-block;
    }
    #preview-img {
      max-width: 440px;
    }
    #company-logo {
      max-width: 134px;
      max-height: 50px;
    }
    .partner-group {
      width: 50%;
      display: inline-block;
      .select2-container--bootstrap {
        width: 100% !important;
      }
      .partner-list {
        margin-top: 10px;
      }
      .partner {
        margin-right: 5px;
        display: inline-block;
        background: #F4F6F8;
        border-radius: 10px;
        padding: 15px;
      }
    }
    .course-title, .course-desc {
      font-size: 18px;
      line-height: 28px;
      color: #000000;
      margin-bottom: 20px;
    }
    .company-name {
      font-size: 16px;
      line-height: 24px;
      color: #000000;
      margin-top: 15px;
      text-transform: uppercase;
    }
    .review-section {
      width: 440px;
      height: 219px;
      border-radius: 10px;
      img {
        width: 100%;
        height: 100%;
        border-radius: 10px;
      }
    }
    .upload-group {
      position: relative;
      width: 440px;
      background: #161c24;
      opacity: 0.6;
      border-radius: 0px 0px 10px 10px;
      padding: 12px;
      font-size: 14px;
      line-height: 22px;
      color: #f4f6f8;
      font-family: "Sansation-Bold";
      bottom: 46px;
      text-align: center;
      input[type=file] {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        cursor: pointer;
        font-size: 0;
      }
      i { margin-right: 14px; }
    }
    .select2-container {
      border: 2px solid #DFE3E8;
      border-radius: 10px;
      font-size: 16px;
      line-height: 24px;
      color: #637381;
      padding: 6px 35px 6px 18px;
      width: 100%;
      min-height: 52px;
      background: #FFF;
      input, textarea {
        padding: 0 !important;
        border: 0 !important;
        --tw-ring-inset: none;
        height: 32px;
        margin: 0;
      }
    }
    .select2-container--bootstrap .select2-selection {
      box-shadow: none;
      background-color: transparent;
      border: none;
      border-radius: 0px;
      color: #637381;
      min-height: 28px;
    }
    .select2-selection__choice {
      margin: 3px 5px 3px 0;
      padding: 3px 15px;
      background: #F4F6F8;
      border: 1px solid #DFE3E8;
      border-radius: 8px;
      display: inline-block;
    }
    .select2-selection__choice__remove {
      float: right;
      margin-left: 10px;
      border: 0;
    }
  }
  .course-unit-list {
    .title {
      font-family: "Sansation-Bold";
      font-size: 18px;
      line-height: 24px;
      color: #161C24;
      margin-bottom: 20px;
    }
    .add-new-unit {
      font-family: "Sansation-Bold";
      font-size: 18px;
      line-height: 24px;
      color: #4271FF;
      text-decoration: none;
      img {
        margin-right: 18px;
        display: inline-block;
      }
    }
    .panel-group {
      background: #F4F7F9;
      box-shadow: 0px 20px 40px -8px rgba(145, 158, 171, 0.1);
      border-radius: 20px;
      padding: 25px 23px;
    }
    .unit-list {
      .unit-content {
        width: calc(100% - 300px);
        display: inline-block;
        margin-right: 36px;
        vertical-align: top;
        input, textarea {
          width: 100%;
          border: 2px solid #DFE3E8;
          border-radius: 10px;
          padding: 11px 18px;
          font-size: 16px;
          line-height: 24px;
          color: #637381;
          background: transparent;
          margin-bottom: 10px;
        }
      }
      .controls-unit {
        display: inline-block;
        input, .cancel-unit {
          font-size: 16px;
          line-height: 24px;
          color: #4271FF;
          padding: 11px 32px;
          border: 2px solid #4271FF;
          background: transparent;
          border-radius: 9px;
          height: 50px;
        }
        .cancel-unit {
          color: #212B36;
          border: 2px solid #DFE3E8;
          margin-left: 20px;
          padding: 14px 23px;
          text-decoration: none;
          cursor: pointer;
        }
      }
      .panel {
        box-shadow: none;
        padding-left: 27px;
        border-radius: 0;
        position: relative;
        margin-top: 0;
        background: transparent;
        min-height: 65px;
      }
      .num {
        width: 53px;
        height: 53px;
        background: #80A3FF;
        border-radius: 50%;
        position: absolute;
        top: 0px;
        left: 0px;
        font-weight: 700;
        font-size: 16px;
        line-height: 16px;
        color: #FFF;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .panel-content {
        padding: 0px 45px;
        border-left: 2px solid #E8EEFF;
      }
      .panel-heading {
        padding: 0;
        margin-bottom: 5px;
        a {
          text-decoration: none;
          font-size: 18px;
          line-height: 24px;
          color: #092651;
          font-family: "Sansation-Bold";
        }
        .edit-unit {
          margin-right: 10px;
          float: right;
        }
        a.unit-title:after {
          font-family: 'Glyphicons Halflings';
          content: '\e114';
          float: right;
          color: #777E91;
          font-size: 16px;
          font-weight: 300;
        }
        a.unit-title.collapsed:after {
          font-family: "Glyphicons Halflings";
          content: '\e113';
        }
      }
      .panel-body {
        border: 0;
        padding: 0;
      }
      .unit-desc {
        font-size: 16px;
        line-height: 24px;
        color: #637381;
        margin-bottom: 25px;
      }
      .add-new-lesson {
        font-size: 16px;
        line-height: 24px;
        color: #4271FF;
        padding: 0;
        text-decoration: none;
        cursor: pointer;
        display: block;
        margin-bottom: 15px;
        i { margin-right: 10px; }
      }
      .lesson-list a {
        text-decoration: none;
      }
      .lesson-container {
        border: 2px solid #DFE3E8;
        border-radius: 10px;
        margin-bottom: 20px;
        position: relative;
        .lesson-content {
          display: flex;
          align-items: center;
        }
        .delete-lesson {
          position: absolute;
          top: 20px;
          right: 10px;
          color: #637381;
          cursor: pointer;
          z-index: 1;
        }
      }
      .lesson-thumb {
        width: 205px;
        height: 134px;
        border-radius: 10px;
        display: inline-block;
      }
      .lesson-thumb img {
        width: 100%;
        height: 100%;
        border-radius: 10px;
      }
      .lesson-detail {
        width: calc(100% - 200px);
        display: inline-block;
        padding: 11px 23px;
      }
      .lesson-detail .title-section {
        display: flex;
      }
      .lesson-detail .title-section .right-section {
        margin-left: auto;
        width: 60%;
        text-align: right;
      }
      .lesson-detail .status, .lesson-detail .current-tag {
        font-family: "Sansation-Bold";
        font-size: 12px;
        line-height: 18px;
        color: #4271FF;
        padding: 4px 12px;
        background: #E8EEFF;
        border-radius: 8px;
        margin-left: 12px;
        display: inline-block;
      }
      .lesson-detail .title {
        margin-bottom: 4px;
      }
      .lesson-detail .video-info {
        font-size: 16px;
        line-height: 24px;
        color: #777777;
      }
      .lesson-detail .desc {
        font-size: 14px;
        line-height: 22px;
        color: #222222;
        margin-top: 4px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        height: 44px;
      }
    }
  }
}
