.choices {
  .choices__inner {
    @apply bg-neutral-50 form-control border ring-primary-500;

    &:focus-within {
      @apply ring-1;
    }
    &:focus {
      @apply ring-primary-200;
    }
    .choices__input {
      background: inherit;
      @apply py-1 px-1;
      &:focus {
        @apply ring-0;
      }
    }
  }
}
