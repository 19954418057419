/*
 *= require management_app/course_list
 *= require management_app/modal
 *= require management_app/course
*/
.course-main-container {
  .unpublish-module-btn {
    display: inline-flex;
    background: #DFE3E8;
    box-shadow: 0px 4px 16px -7px rgba(180, 182, 254, 0.2);
    border-radius: 9px;
    font-family: "Sansation-Bold";
    font-size: 16px;
    line-height: 24px;
    color: #212B36;
    text-decoration: none;
    align-items: center;
    img, i {
      margin-right: 11px;
    }
    img { width: 10px; }
  }
}
