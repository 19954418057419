body {
  background: #E5EDF5;
  font-family: 'Sansation', sans-serif;
  font-style: normal;
  font-weight: 400;
  height: 100vh;
}
#main-wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
}
.main-container {
  margin-top: 40px;
  margin-right: 30px;
  margin-bottom: 45px;
  display: inline-block;
  width: calc(100% - 335px);
  a { text-decoration: none; }
}
.ng-main-contain {
  background-repeat: no-repeat;
  background-position: left bottom;
  background-size: contain;
  width: 155px;
  height: 91px;
  position: absolute;
  right: 0;
  top: 170px;
  z-index: -1;
}
.ng-main-background {
  background-repeat: no-repeat;
  background-position: left bottom;
  background-size: contain;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: -1;
}
