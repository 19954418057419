#newCourse, #newCompany, #newUnit, #newUnitLesson, #editUnit, #editQuestion {
  .modal-content {
    background: #F9FAFB;
    box-shadow: 0px 20px 40px -8px rgba(145, 158, 171, 0.24);
    border: 0;
    border-radius: 15px;
    position: relative;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }
  .modal-header {
    padding: 14px 26px;
    position: relative;
    h2 {
      font-family: 'Sansation-Bold';
      font-size: 24px;
      line-height: 36px;
      color: #00269A;
      margin: 0;
    }
    .close {
      position: absolute;
      top: 22px;
      right: 26px;
      opacity: 1;
      border: 0;
    }
  }
  .modal-body {
    padding: 0px 26px 25px;
    .subtitle {
      font-family: "Sansation-Bold";
      font-size: 16px;
      line-height: 24px;
      color: #454F5B;
      margin-top: 20px;
      margin-bottom: 10px;
    }
  }
  .file-upload-section {
    .file-upload-group {
      border: 1px dashed rgba(0, 0, 0, 0.25);
      box-sizing: border-box;
      border-radius: 8px;
      padding: 32px;
      text-align: center;
      margin-bottom: 20px;
      .upload-group {
        position: relative;
        font-weight: 700;
        font-family: "Sansation-Bold";
        font-size: 18px;
        line-height: 26px;
        text-align: center;
        color: #4271FF;
        border-radius: 10px;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 185px;
        margin: 0 auto;
        img {
          width: 40px;
        }
        input[type=file] {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          opacity: 0;
          cursor: pointer;
          font-size: 0;
        }
      }
      span {
        font-family: 'Sansation';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: #919EAB;
      }
    }
    .explain-group {
      &.uploaded {
        display: none;
      }
      &.disabled {
        pointer-events: none;
        opacity: 0.25;
        display: inline-block;
      }
    }
  }
  form {
    position: relative;
    textarea {
      width: 100%;
      border: 2px solid #DFE3E8;
      border-radius: 10px;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #637381;
      padding: 13px 18px;
    }

    .text-input {
      padding: 13px 18px;
      border: 2px solid #DFE3E8;
      border-radius: 10px;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #637381;
      width: 100%;
    }
    .errorMsg {
      color: #DC4D57;
      font-size: 14px;
      line-height: 20px;
    }
    .submit-btn {
      font-size: 18px;
      line-height: 26px;
      color: #FFFFFF;
      font-family: "Sansation-Bold";
      padding: 11px 22px;
      background: #4271FF;
      border-radius: 10px;
      display: inline-block;
      margin-top: 30px;
      border: 0;
      align-items: center;
      height: 48px;
    }
    .action-controls {
      width: 100%;
      text-align: center;
    }
    .review-section {
      margin-bottom: 15px;
      i { cursor: pointer }
    }
    .preview-company-logo {
      width: 402px;
      height: 72px;
    }
    .preview-course-thumbnail {
      width: 402px;
      height: 480px;
    }
  }

  .select2-container {
    border: 2px solid #DFE3E8;
    border-radius: 10px;
    font-size: 16px;
    line-height: 24px;
    color: #637381;
    width: 100%;
    min-height: 52px;
    input, textarea {
      height: 28px;
      padding: 0;
      color: #637381;
      margin: 3px 0;
      --tw-ring-inset: none;
      border: 0;
      background: transparent;
    }
    .select2-search__field {
      padding: 3px 10px;
    }
  }
  .select2-container--bootstrap .select2-selection {
    box-shadow: none;
    background-color: transparent;
    border: none;
    border-radius: 0px;
    color: #637381;
    min-height: 28px;
    padding: 6px 35px 6px 18px;
  }
  .select2-selection__choice, .tag-list .tag {
    margin-top: 15px;
    margin-right: 20px;
    padding: 11px 22px;
    background: #F4F6F8;
    border: 1px solid #DFE3E8;
    border-radius: 8px;
    display: inline-block;
    .tag-name {
      font-size: 15px;
      line-height: 22px;
      color: #454F5B;
      font-family: "Sansation-Bold";
      display: inline-block;
    }
    i { cursor: pointer; }
  }
  .select2-selection__choice {
    margin: 3px 5px 3px 0;
    padding: 3px 15px;
    display: inline-block;
  }
  .select2-selection__choice__remove {
    float: right;
    margin-left: 10px;
    border: 0;
  }
  .select2-container--bootstrap .select2-selection--multiple {
    input {
      padding: 0
    }
  }
  .select2-container--bootstrap .select2-selection--single .select2-selection__rendered {
    padding: 8px 0;
  }
  .employer-partner {
    .partner-logo img {
      height: 24px;
    }
    .partner-name {
      margin-top: 15px;
      font-size: 14px;
      line-height: 22px;
      color: #000000;
      text-transform: uppercase;
    }
    .partner-list {
      margin-top: 20px;
    }
    .partner {
      margin-right: 5px;
      display: inline-block;
      background: #F4F6F8;
      border-radius: 10px;
      padding: 15px;
      max-width: 134px;
      img { width: 100%; }
    }
  }
}
