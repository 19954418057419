
@font-face {
  font-family: "Assistant";
  font-style: normal;
  font-weight: 400;
  src: local(""),
    url("../fonts/assistant-v8-latin-ext-regular.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("../fonts/assistant-v8-latin-ext-regular.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* assistant-600 - latin-ext */
@font-face {
  font-family: "Assistant";
  font-style: normal;
  font-weight: 600;
  src: local(""),
    url("../fonts/assistant-v8-latin-ext-600.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("../fonts/assistant-v8-latin-ext-600.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* assistant-700 - latin-ext */
@font-face {
  font-family: "Assistant";
  font-style: normal;
  font-weight: 700;
  src: local(""),
    url("../fonts/assistant-v8-latin-ext-700.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("../fonts/assistant-v8-latin-ext-700.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* assistant-800 - latin-ext */
@font-face {
  font-family: "Assistant";
  font-style: normal;
  font-weight: 800;
  src: local(""),
    url("../fonts/assistant-v8-latin-ext-800.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("../fonts/assistant-v8-latin-ext-800.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
@font-face {
  font-family: 'Untitled Sans';
  src: url("../fonts/untitled-sans-medium.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Untitled Sans';
  src: url("../fonts/untitled-sans-regular-italic.woff2") format("woff2");
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: 'Untitled Sans';
  src: url("../fonts/untitled-sans-bold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Test Heldane Display';
  src: font-url("../fonts/heldane-display-regular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Sansation';
  src: url("../fonts/Sansation_Regular.ttf") format("truetype");
}
@font-face {
  font-family: 'Sansation-Light';
  src: url("../fonts/Sansation_Light.ttf") format("truetype");
}
@font-face {
  font-family: 'Sansation-Bold';
  src: url("../fonts/Sansation_Bold.ttf") format("truetype");
}
@font-face {
  font-family: 'Sansation-Italic';
  src: url("../fonts/Sansation_Italic.ttf") format("truetype");
}
@font-face {
  font-family: 'Glyphicons Halflings';
  src: url('../fonts/glyphicons-halflings-regular.eot');
  src: url("../fonts/glyphicons-halflings-regular.woff2") format("woff2"), url("../fonts/glyphicons-halflings-regular.woff") format("woff"), url("../fonts/glyphicons-halflings-regular.ttf") format("truetype");
}
