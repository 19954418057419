@mixin button {
  @apply inline-flex px-4 rounded-full text-button items-center justify-center no-underline whitespace-nowrap transition-all duration-150 ease-out cursor-pointer;
  height: 36px;
  line-height: 36px;
  letter-spacing: 0.03em;

  &:focus {
    @apply outline-none;
  }
}

@mixin button-small {
  @apply text-sm font-normal px-2;
  height: 32px;
}

@mixin disabled-button {
  @apply bg-legacyColor-neutral-2-100 text-legacyColor-neutral-2-500 cursor-not-allowed;
}

.btn-disabled {
  @include button;
  @include disabled-button;
}

.btn-primary {
  @include button;
  @apply bg-legacyColor-secondary-500 text-legacyColor-secondary-50;

  &:hover {
    @apply bg-legacyColor-secondary-600;
  }

  &:active {
    @apply bg-legacyColor-secondary-700;
  }

  &:disabled {
    @include disabled-button;
  }
}

.btn-secondary {
  @include button;
  @apply text-legacyColor-neutral-1-500;

  &:hover {
    @apply bg-legacyColor-primary-200;
  }

  &:active {
    @apply bg-legacyColor-primary-600;
  }

  &:disabled {
    @include disabled-button;
  }
}

.btn-tertiary {
  @include button;
  @include button-small;
  @apply text-legacyColor-neutral-1-500 bg-legacyColor-primary-100;

  &:hover {
    @apply bg-legacyColor-primary-200;
  }

  &:active {
    @apply bg-legacyColor-primary-600;
  }

  &:disabled {
    @include disabled-button;
  }
}

// Above this line is legacy styles
@mixin new-button {
  @apply rounded-full font-bold text-secondary-700 inline-flex justify-center no-underline whitespace-nowrap transition-all duration-150 ease-out cursor-pointer;
  letter-spacing: 0.03em;
  height: fit-content; //temporal override for legacy .btn-primary
  line-height: inherit; //temporal override for legacy .btn-primary
}

@mixin new-disabled-button {
  @apply text-neutral-500 cursor-not-allowed;
}

@layer components {
  .meander {
    .btn-primary {
      @include new-button;
      @apply bg-primary-100;

      &:hover {
        @apply bg-primary-50;
      }

      &:active {
        @apply bg-primary-200;
      }

      &:disabled {
        @include new-disabled-button;
        @apply bg-neutral-200;
      }
    }

    .btn-secondary {
      @include new-button;
      @apply bg-shade-50 outline outline-2 outline-offset-[-2px] outline-secondary-100;

      &:hover {
        @apply outline-secondary-300;
        background-color: initial;
      }

      &:active {
        @apply outline-secondary-600;
      }

      &:focus {
        @apply outline-secondary-600 outline-offset-[-2px];
      }

      &:disabled {
        @include new-disabled-button;
        @apply bg-neutral-50 border-neutral-500 outline-neutral-500;
      }
    }

    .btn-sm {
      @apply py-2 px-4 text-sm uppercase leading-[1.0625rem] tracking-[0.08em];
    }
    .btn-md {
      @apply py-3 px-[1.125rem] text-base uppercase leading-[1.25rem] tracking-[0.08em];
    }
    .btn-lg {
      @apply py-3.5 px-5 text-lg uppercase leading-[1.3125rem] tracking-[0.08em];
    }
  }
}
