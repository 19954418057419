@layer base {
  input[type="checkbox"] {
    @apply border-2 border-legacyColor-neutral-1-500 text-legacyColor-neutral-1-500;
    &:hover {
      @apply bg-legacyColor-primary-200;
    }

    &:focus {
      @apply ring-legacyColor-neutral-1-500;
    }

    &:active {
      @apply ring-legacyColor-neutral-1-500 bg-legacyColor-primary-500;
    }

    &:disabled {
      @apply ring-neutral-200 border-neutral-200;
    }

    &:checked {
      @apply text-legacyColor-neutral-1-500;

      &:hover {
        @apply text-legacyColor-primary-500 border-legacyColor-neutral-1-500;

        &:focus {
          @apply ring-legacyColor-neutral-1-500;
        }
      }
    }
  }

  input[type="checkbox"] {
    @apply rounded;

    &:checked {
      &:hover {
        background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='%2312414A' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
      }
    }
  }

  input[type="radio"] {
    @apply border border-legacyColor-neutral-1-500 text-legacyColor-primary-200;

    &:checked {
      @apply text-legacyColor-primary-200 border-legacyColor-neutral-1-500;

      background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='%2312414A' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='4'/%3e%3c/svg%3e");

      &:focus {
        @apply ring-legacyColor-neutral-1-500;
      }

      &:hover {
        @apply text-legacyColor-primary-500 border-legacyColor-neutral-1-500;

        background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='%2312414A' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='4'/%3e%3c/svg%3e");
      }
    }
  }
}

// *****************************************************************
// *****************************************************************
// Styles for form input
@layer utilities {
  .disabled {
    @apply bg-legacyColor-neutral-2-500
            border-legacyColor-neutral-2-500
            hover:cursor-not-allowed;
  }
  .form-label {
    @apply block mb-2 p1;

    &.form-radio-label {
      @apply inline-block text-base m-0;
    }
  }

  .form-select,
  .form-control {
    @apply border-neutral-400
        text-shade-100
          rounded-lg
          w-full
          focus:ring-2
        focus:ring-primary-200
        focus:border-primary-500
        hover:border-primary-500
          p-3
          p1;
    background-position: right 10px top 50%;
    background-repeat: no-repeat;
    background-size: 1.5rem;

    &.down-icon {
      background-image: url("icons/down.svg");
    }
  }

  .form-select {
    @apply bg-none pr-10;

    &:disabled {
      @apply disabled;
    }
  }

  .form-control {
    @apply block;

    &.calendar-icon {
      background-image: url("icons/calendar.svg");
    }

    &:disabled {
      @apply disabled;
    }

    &.flatpickr-alt-input {
      &.active {
        @apply ring-2 ring-primary-200 border-primary-500;
      }
    }
  }

  .form-error {
    @apply text-error-600;
  }
}

.field_with_errors {
  @apply flex w-full;

  .form-label {
    @apply text-error-600;
  }

  .form-radio-label {
    @apply text-error-600 text-base;
  }

  .form-control,
  .form-select,
  .form-radio {
    @apply border-error-600;
  }
}
