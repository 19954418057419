/*
 *= require management_app/modal
*/
.company-card-container {
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  column-gap: 20px;
  row-gap: 20px;
}
.page-title {
  display: flex;
  margin-bottom: 26px;
  align-items: center;
  color: #00269A;
}
.create-new-company {
  margin-left: auto;
  a {
    background: #4271FF;
    box-shadow: 0px 4px 16px -7px rgba(180, 182, 254, 0.2);
    border-radius: 9px;
    font-family: "Sansation-Bold";
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    padding: 11px 22px;
    text-decoration: none;
    i {
      margin-right: 10px;
    }
    &:hover, &:focus {
      color: #FFFFFF;
    }
  }
}
.company-card {
  width: 227px;
  height: 188px;
  padding: 20px;
  background: #FFFFFF;
  box-shadow: 0px 20px 40px -8px rgba(145, 158, 171, 0.1);
  border-radius: 10px;
  text-align: center;
  position: relative;
  .remove-company {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    z-index: 1;
  }
  a {
    text-decoration: none;
  }
  .company-logo {
    margin-bottom: 8px;
    img {
      height: 24px;
    }
  }
  .company-name {
    font-size: 16px;
    line-height: 24px;
    color: #092651;
    margin-bottom: 10px;
    font-family: "Sansation-Bold";
    text-transform: uppercase;
  }
  .company-industry {
    font-size: 12px;
    line-height: 18px;
    color: #454F5B;
  }
  .company-desc {
    margin-top: 10px;
    font-size: 12px;
    line-height: 18px;
    color: #637381;
    text-align: left;
    height: 54px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
}
