:root {
  --header-radius: 80px;
}

.nexusedge {
  header {
    @apply bg-primary-50;

    border-bottom-right-radius: var(--header-radius);
    position: relative;

    &::before {
      content: "";
      position: absolute;

      background-color: transparent;
      top: 100%;
      left: 0;
      height: calc(2 * var(--header-radius));
      width: var(--header-radius);
      border-top-left-radius: var(--header-radius);
      box-shadow: 0 calc(-1 * var(--header-radius)) 0 0
        theme("colors.primary.50");
    }
  }
}

$breakpoint-md: 768px;
@media (max-width: $breakpoint-md) {
  :root {
    --header-radius: 60px;
  }
}
