.question-form {
  margin-bottom: 40px;
  position: relative;
  .delete_question_new {
    font-size: 16px;
    line-height: 24px;
    color: #919EAB;
    font-family: "Sansation-Bold";
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 50%;
    padding: 5 8px;
    border: 2px solid #919EAB;
    opacity: 0.8;
  }
  .title {
    font-size: 16px;
    line-height: 24px;
    color: #161C24;
    font-family: "Sansation-Bold";
    margin-bottom: 20px;
  }
  .question-type {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    .title { margin-bottom: 0 !important; }
    select {
      width: 250px;
      border: 2px solid #DFE3E8;
      border-radius: 10px;
      padding: 11px 18px;
      font-size: 16px;
      line-height: 24px;
      color: #161C24;
      margin-left: 30px;
      background: transparent;
    }
  }
  .question-content {
    margin-bottom: 25px;
  }
  .choiceAnswer, .choiceAnswerBoolean {
    .desc {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: #161C24;
      margin-bottom: 21px;
    }
    .answer-list {
      padding: 18px 16px;
      background: #F9FAFB;
      box-shadow: 0px 20px 40px -8px rgba(145, 158, 171, 0.1);
      border-radius: 10px;
      .answer-content {
        width: calc(100% - 52px) !important;
        padding: 15px !important;
        border: none !important;
        margin-left: auto;
        border-radius: 0 8px 8px 0;
        &:focus-visible {
          outline: none;
        }
      }
      ol {
        padding: 0;
        list-style-type: upper-alpha;
      }
      .question-choice-content {
        display: inline-flex;
        width: calc(100% - 30px);
        border: 2px solid #E8EEFF;
        border-radius: 10px;
        position: relative;
        counter-increment: customlistcounter;
        position: relative;
        z-index: 0;
        text-align: right;
        cursor: pointer;
        background: #F9FAFB;
        margin-bottom: 20px;
        &:hover {
          border: 2px solid #4271FF;
        }
      }
      .question-choice-content::before {
        content: counter(customlistcounter, upper-alpha) " ";
        font-size: 18px;
        line-height: 24px;
        color: #092651;
        background: #E8EEFF;
        border-radius: 8px;
        text-align: center;
        left: 0;
        top: 0;
        height: 100%;
        width: 52px;
        padding: 14px 19px;
        position: absolute;
        transition: all 0.2s ease-in-out;
        z-index: -1;
        display: flex;
        align-items: center;
      }
      [type=radio] {
        position: absolute;
        opacity: 0;
        width: 52px !important;
        height: 100% !important;
        cursor: pointer;
      }
      .delete_answer_link {
        color: #161C24;
        margin-left: 10px;
        height: 100%;
      }
      li::marker { content: "" }
      li:nth-child(1) {
        counter-reset: customlistcounter;
      }
      li.correct .question-choice-content {
        border: 2px solid #19B577;
      }
      li:hover .question-choice-content::before {
        background-color: #4271FF;
        color: #FFF;
      }
      li.correct .question-choice-content::before {
        background-color: #19B577;
        color: #FFF;
      }
    }
  }
  .choiceFreeResponse {
    .desc {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: #161C24;
      margin-bottom: 21px;
    }
    .checkbox-item {
      margin-bottom: 15px;
      position: relative;
      input {
        padding: 0;
        margin-bottom: 0;
        cursor: pointer;
        position: absolute;
        z-index: 1;
        width: 24px;
        height: 24px;
        margin-top: 0;
        opacity: 0;
      }

      label {
        position: relative;
        cursor: pointer;
        font-size: 16px;
        line-height: 24px;
        color: #000000;
        margin-left: 0;
        display: flex;
        align-items: center;
      }

      label:before {
        content:'';
        -webkit-appearance: none;
        background-color: transparent;
        border: 2px solid #C4CDD5;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
        border-radius: 8px;
        padding: 10px;
        display: inline-block;
        position: relative;
        vertical-align: middle;
        cursor: pointer;
        margin-right: 15px;
      }
      input:checked + label:before {
        border: 2px solid #34A6F9;
      }
      input:checked + label:after {
        content: '';
        display: block;
        position: absolute;
        top: 2px;
        left: 9px;
        width: 6px;
        height: 14px;
        border: solid #34A6F9;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
      }
    }
  }
  .add_question_choice_forms {
    font-family: "Sansation-Bold";
    font-size: 18px;
    line-height: 24px;
    color: #4271FF;
    margin-top: 20px;
    text-decoration: none;
  }
}
