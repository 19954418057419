.flash {
  @apply z-[999]
        py-4
        px-6
        mt-4
        flex
        justify-between
        items-center
        text-center
        rounded-full
        bg-tertiary-100
        text-tertiary-800
        uppercase
        text-button-md
        font-bold;
  opacity: 0;
  transition: all 0.2s ease-in-out,
    background-color 0.3s cubic-bezier(0.42, 0, 0.84, 0.22) 0.3s;
  &.flash-active {
    opacity: 0.93;
  }
  &.flash-shake {
    animation-delay: 1s;
    animation: shake 0.5s;
  }
  &.flash-alert {
    @apply bg-warning-100 text-warning-600;
  }
}

@keyframes shake {
  0% {
    transform: translate(0, -1px) rotate(0deg);
  }
  10% {
    transform: translate(0, 0) rotate(-1deg);
  }
  20% {
    transform: translate(0, 1px) rotate(1deg);
  }
  30% {
    transform: translate(0, 0) rotate(0deg);
  }
  40% {
    transform: translate(0, -1px) rotate(1deg);
  }
  50% {
    transform: translate(0, 0) rotate(-1deg);
  }
  60% {
    transform: translate(0, 1px) rotate(0deg);
  }
  70% {
    transform: translate(0, 0) rotate(-1deg);
  }
  80% {
    transform: translate(0, -1px) rotate(1deg);
  }
  90% {
    transform: translate(0, 0) rotate(0deg);
  }
}
