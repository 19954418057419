/*
 *= require "management_app/question_form"
 *= require "management_app/modal"
 */
.unit-lesson-main-container {
  .page-title {
    display: flex;
    margin-bottom: 26px;
    align-items: center;
    color: #00269A;
    h2 {
      margin: 0;
      width: 60%;
      font-size: 24px;
      line-height: 36px;
      color: #00269A;
      font-family: "Sansation-Bold";
    }
  }
  .back-btn {
    color: #00269A;
    margin-right: 19px;
    font-size: 17px;
    display: flex;
    align-items: center;
  }
  .unit-lesson-tabs {
    margin-bottom: 25px;
    .nav-tabs {
      border-bottom: none;
      li {
        padding-right: 40px;
        a, a:hover, a:focus {
          font-family: "Sansation-Bold";
          font-size: 18px;
          line-height: 24px;
          border: 0;
          padding: 0 0 5px 0;
          color: #637381;
          background: transparent;
        }
      }
      li {
        a.active, a:hover, a:focus {
          color: #4271FF;
          background: transparent;
          border: 0;
          border-bottom: 1.5px solid #4271FF;
        }
      }
    }
  }
  #newLessonForm {
    .title {
      font-family: "Sansation-Bold";
      font-size: 18px;
      line-height: 24px;
      color: #161C24;
      margin-bottom: 15px;
    }
    .thumbnail-partner {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-bottom: 30px;
    }
    .thumbnail-group {
      width: 50%;
      display: inline-block;
    }
    .detail-group {
      width: 100%;
      display: inline-block;
    }
    .lesson-title, .lesson-desc {
      font-size: 18px;
      line-height: 28px;
      color: #000000;
      margin-bottom: 20px;
    }
    textarea {
      width: 100%;
      border: 2px solid #DFE3E8;
      border-radius: 10px;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #637381;
      padding: 13px 18px;
      margin-bottom: 20px;
      --tw-ring-inset: none;
    }

    input {
      padding: 11px 18px;
      border: 2px solid #DFE3E8;
      border-radius: 10px;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #637381;
      width: 100%;
      --tw-ring-inset: none;
    }
    .errorMsg {
      color: #DC4D57;
      font-size: 14px;
      line-height: 20px;
    }
    .save-draft {
      font-size: 18px;
      line-height: 26px;
      color: #FFFFFF;
      font-family: "Sansation-Bold";
      padding: 11px 22px;
      background: #4271FF;
      border-radius: 10px;
      display: inline-block;
      margin-top: 30px;
      border: 0;
      align-items: center;
      width: auto;
      height: 48px;
    }
    input[type=radio] {
      width: 24px;
      height: 24px;
      border: 2px solid #C4CDD5;
      border-radius: 24px;
      vertical-align: middle;
      margin-top: 0;
    }
    label {
      font-size: 16px;
      line-height: 24px;
      color: #454F5B;
      margin-left: 15px;
      margin-bottom: 0;
    }
    .lesson-type-container {
      display: flex;
      justify-content: space-between;
      .lesson-type {
        width: 50%;
      }
    }
    .review-section {
      width: 440px;
      height: 219px;
      border-radius: 10px;
      img {
        width: 100%;
        height: 100%;
        border-radius: 10px;
      }
    }
    .upload-group {
      position: relative;
      width: 440px;
      background: #161C24;
      opacity: 0.6;
      border-radius: 0px 0px 10px 10px;
      padding: 12px;
      font-size: 14px;
      line-height: 22px;
      color: #F4F6F8;
      font-family: "Sansation-Bold";
      bottom: 46px;
      text-align: center;
      input[type=file] {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        cursor: pointer;
        font-size: 0;
      }
      i { margin-right: 14px; }
    }
    .add_question_forms {
      font-family: "Sansation-Bold";
      font-size: 18px;
      line-height: 24px;
      color: #4271FF;
      border: 2px solid #4271FF;
      padding: 10px 15px;
      margin-top: 20px;
      border-radius: 8px;
      text-decoration: none;
    }
  }
  .lesson-metadata {
    .select2-container {
      border: 2px solid #DFE3E8;
      border-radius: 10px;
      font-size: 16px;
      line-height: 24px;
      color: #637381;
      padding: 6px 35px 6px 18px;
      width: 100%;
      min-height: 52px;
      background: #FFF;
      input, textarea {
        padding: 0 !important;
        border: 0 !important;
        --tw-ring-inset: none;
        height: 32px;
        margin: 0 !important;
      }
    }
    .select2-container--bootstrap .select2-selection {
      box-shadow: none;
      background-color: transparent;
      border: none;
      border-radius: 0px;
      color: #637381;
      min-height: 28px;
      ul { display: inline-blockl  }
    }
    .select2-selection__choice {
      margin: 3px 5px 3px 0;
      padding: 3px 15px;
      display: inline-block;
    }
    .select2-selection__choice__remove {
      float: right;
      margin-left: 10px;
      border: 0;
    }
  }
  .question-list {
    .question-card {
      width: 475px;
      height: 124px;
      display: inline-block;
      background: #FFFFFF;
      box-shadow: 0px 20px 40px -8px rgba(145, 158, 171, 0.1);
      border-radius: 10px;
      padding: 21px;
      margin-bottom: 40px;
      margin-right: 40px;
      .question-header {
        display: flex;
        align-items: center;
        margin-bottom: 14px;
      }
      .title {
        font-family: "Sansation-Bold";
        font-size: 18px;
        line-height: 24px;
        color: #161C24;
        display: inline-block;
        max-width: 65%;
        overflow: hidden;
        margin-bottom: 0 !important;
        p { margin: 0; }
      }
      .break-point {
        display: inline-block;
        margin: 0 12px;
      }
      .question-type {
        display: inline-block;
        font-size: 16px;
        line-height: 24px;
        color: #161C24;
      }
      .question-desc {
        font-size: 14px;
        line-height: 22px;
        color: #454F5B;
        height: 44px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      .controls-action {
        margin-left: auto;
      }
      .delete-question {
        display: inline-block;
        color: #161C24;
        margin-left: 15px;
      }
      .edit-question {
        display: inline-block;
        color: #161C24;
      }
    }
  }
}
@media all and (min-width: 997px) {
  .unit-lesson-main-container #newLessonForm .thumbnail-partner {
    flex-direction: row;
  }
  .unit-lesson-main-container #newLessonForm .detail-group {
    width: 50%;
  }
}
